<template>
  <div class="j-header">
    <div class="j-header-menu">
      <img class="j-header-menu-logo" src="../assets/logo.png" />
      <ul class="j-header-menu-ul">
        <li v-for="(item,index) in menuList" :key="index" @click="setActive(item,index)">
          <div class="menu-name">{{item.name}}</div>
          <div v-if="item.active" class="menu-active"></div>
        </li>
      </ul>
    </div>
    <div style="width: 100%;margin: 0 auto;" @mouseenter="on_bot_enter" @mouseleave="on_bot_leave">
      <swiper :options="swiperOption" ref="mySwiper">
        <swiper-slide>
          <img class="j-header-img" src="../assets/banner01.png" alt="">
        </swiper-slide>
        <swiper-slide>
          <img class="j-header-img" src="../assets/banner02.png" alt="">
        </swiper-slide>
        <swiper-slide>
          <img class="j-header-img" src="../assets/banner03.png" alt="">
        </swiper-slide>
      </swiper>
      <div class="j-swiper-pagination">
        <div class="swiper-pagination" ref="pagination" :style="{width: (paginationW!==0 ? paginationW+'px' : ''),margin: '0 auto'}"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuList: [{
        name: '首页',
        active: true,
        path: '/index'
      },{
        name: '产品介绍',
        active: false,
        path: '/product'
      },{
        name: '新闻动态',
        active: false,
        path: '/news'
      },{
        name: '关于我们',
        active: false,
        path: '/aboutUs'
      }],
      paginationW: 0,
      swiperOption: {//swiper3
        speed: 1000,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },  //选择器配置
        autoplay: {
          delay: 5000, //延迟5秒自动播放
          stopOnLastSlide: false, //到最后一张图片是否停止自动播放。
          disableOnInteraction: false  //用户操作后是否停止自动播放。
        },
      }
    }
  },
  mounted() {
    this.paginationW = this.$refs.pagination.clientWidth;
    console.log('宽度', this.paginationW)
  },
  methods: {
    //通过获得的swiper对象来暂停自动播放
    on_bot_enter() { //鼠标移入停止自动播放
      this.mySwiper.autoplay.stop()
    },
    on_bot_leave() {//鼠标移出停止自动播放
      this.mySwiper.autoplay.start()
    },
    setActive(item) {
      this.$router.push({path: item.path})
    }
  },
  //计算属性，获得可以操作的swiper对象
  computed: {
    mySwiper() {
      // mySwiper 是要绑定到标签中的ref属性
      return this.$refs.mySwiper.swiper
    },
  },
  // 修改写法
  watch: {
    '$route.path': {
      immediate: true,
      handler(to) {
        this.menuList.forEach((menuItem) => {
          if(to == menuItem.path) {
            this.$set(menuItem, 'active', true)
          }else {
            this.$set(menuItem, 'active', false)
          }
        });
      }
    }
  }
}
</script>
<style>
.j-header{
  position: relative;
  width: 100%;
  /*height: 600px;*/
}
.swiper-pagination{
  transform: translate3d(0, -30px, 0) !important;
  position: static !important;
}
.swiper-pagination-bullet{
  width: 107px !important;
  height: 4px !important;
  border-radius: 0 !important;
  margin-right: 40px;
  opacity: 1 !important;
  background: #7E7E7E !important;
}
.swiper-pagination-bullet:last-child{
  margin-right: 0;
}
.swiper-pagination-bullet-active{
  background: #FFFFFF !important;
}
.j-header-menu{
  position: absolute;
  width: 1200px;
  height: 100px;
  line-height: 100px;
  left: 50%;
  margin-left: -600px;
  z-index: 100;
}
.j-header-menu-logo{
  width: 161px;
  height: 108px;
  float: left;
}
.j-header-img{
  /*height: 600px;*/
  width: 100%;
}
.j-swiper-pagination{
  position: absolute;
  width: 100%;
  z-index: 10;
}
.j-header-menu-ul{
  color: #FFFFFF;
  float: right;
  height: 100px;
  display: flex;
  align-items: center;
}
.j-header-menu-ul li{
  list-style-type:none;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
}
.menu-active{
  width: 100%;
  height: 6px;
  background: #4977E8;
  opacity: 1;
  border-radius: 4px;
}
.menu-name{
  margin-right: 23px;
  margin-left: 23px;

}
</style>
