<template>
  <div class="j-foot">
    <div class="j-foot-con">
      <ul class="j-foot-con-ul">
        <li>商务联络：BD@jiuguan.link</li>
        <li>加入我们：HR@jiuguan.link</li>
        <li>联系电话：400-681-3033</li>
        <li>公司地址：浙江省杭州市余杭区五常街道高巨大厦A座509室</li>
        <li style="cursor: pointer;" @click="baSub">
          <img class="j-hb" src="../assets/foot-icon1.png" alt="">
          浙公网安备 33010502005795号
        </li>
        <li style="cursor: pointer;" @click="recordQuery">浙ICP备2021016564号</li>
      </ul>
      <img class="logo" src="../assets/logo.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "JFoot",
  methods: {
    baSub() {
      window.location.href = 'http://www.beian.gov.cn/portal/registerSystemInfo?spm=875.7931836/B.a2226n1.72.66144265Mld2Qp&recordcode=33011002015438';
    },
    recordQuery() {
      window.location.href = 'https://beian.miit.gov.cn/#/Integrated/recordQuery';
    }
  }
}
</script>

<style scoped>
.j-foot{
  height: 360px;
  width: 100%;
  background: url("../assets/footImg.png") no-repeat center center;
}
.j-foot-con{
  width: 1200px;
  height: 360px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.j-foot-con-ul{
  text-align: left;
  line-height: 36px;
  color: #FFFFFF;
  font-size: 14px;
  font-family: PingFang SC;
}
.logo{
  width: 336px;
  height: 224px;
}
.j-hb{
  height: 20px;
  width: 20px;
  margin-right: 4px;
}

</style>
