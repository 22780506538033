import Vue from 'vue'
import Router from "vue-router";

Vue.use(Router)


const VueRouterPush = Router.prototype.push
Router.prototype.push = function push (to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

export default new Router({
    routes: [
        {
            path: '/',
            redirect:'/index'
        },
        {
            path: '/index',
            component: () => import('@/view/index/index.vue')
        },
        {
            path: '/product',
            component: () => import('@/view/product/index.vue')
        },
        {
            path: '/news',
            component: () => import('@/view/news/index.vue')
        },
        {
            path: '/aboutUs',
            component: () => import('@/view/aboutUs/index.vue')
        }
    ],
    linkActiveClass:'active'
})
