import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import './util/rem'
import 'swiper/dist/css/swiper.css'  //引入swiper样式，不同版本路径不一样.
import JHeader from '@/components/JHeader'
import JFoot from "@/components/JFoot";

Vue.component('JHeader', JHeader)
Vue.component('JFoot', JFoot)

Vue.config.productionTip = false
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)

router.beforeEach((to, from, next) => {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
  if(flag) {
    window.location.href='http://jiuguan.work/offical_mobile';
    next(false)
    return false;
  }else {
    next()
  }
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
