<template>
  <div class="app" id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted() {
    document.title = '浙江久冠信息技术服务有限公司';
  }
}
</script>

<style>
body{
  padding: 0;
  margin: 0;
}
.app{
  width: 100%;
  overflow-x: hidden;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding: 0;
  margin: 0;
}
ul, li{
  padding: 0;
  margin: 0;
  list-style-type:none;
}
</style>
